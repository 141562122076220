import FavoriteItem from '@/models/FavoriteItem.js'
import store from '@/store'
import Presenter from '@/components/pages/user/favoriteItem/Presenter'

export default {
  data: () => ({
    favoriteItems: []
  }),

  async created () {
    this.favoriteItems = await this.$store.dispatch('favoriteItem/getFavoriteItems')
  },

  render (h) {
    return h(Presenter, {
      props: {
        favoriteItems: this.favoriteItems
      },

      on: {
        async addToCart (itemUuid, count, userNote) {
          const data = {
            itemUuid: itemUuid,
            count: count,
            userNote: userNote
          }
          const cartInfo = await store.dispatch('cart/addItems', data)
          store.commit('updateCartInfo', cartInfo)
          store.commit('message/show', {
            message: 'アイテムをカートに追加しました。',
            color: 'info',
            timeout: 4000
          })
        },
        removeFromFavorite (presenter, itemUuid) {
          presenter.$store.dispatch('favoriteItem/removeFavoriteItem', itemUuid)
            .then(() => {
              const favoriteArray = presenter.favoriteItems
              for (const i in favoriteArray) {
                if (favoriteArray[i].itemUuid === itemUuid) {
                  favoriteArray.splice(i, 1)
                }
              }
              store.commit('message/show', {
                message: 'お気に入りからアイテムを削除しました。',
                color: 'info',
                timeout: 4000
              })
            })
            .catch(error => {
              FavoriteItem.errorHandler(error)
            })
        }
      }
    })
  }
}
