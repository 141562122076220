<template>
  <v-container fluid>
    <v-row
      class="justify-center"
      dense
    >
      <v-col cols="10">
        <h1>お気に入りアイテム</h1>
        <v-row>
          <v-col
            v-for="favorite in favoriteItems"
            :key="favorite.uuid"
            cols="12"
          >
            <v-card
              v-if="!favorite.deletedAt"
              class="mb-10 welfare-card outset"
            >
              <v-row
                dense
                class="mx-3 my-5"
              >
                <v-col
                  cols="3"
                  class="ml-12 d-flex align-center"
                >
                  <router-link :to="{ name: 'ItemDetail', params: { uuid: favorite.itemUuid }}">
                    <v-img
                      v-if="favorite.mainImgSrc"
                      :src="favorite.mainImgSrc"
                      class="small-outset welfare-card ma-10"
                      height="200"
                      width="250"
                    />
                  </router-link>
                </v-col>
                <v-col class="ml-10 pa-12">
                  <router-link
                    :to="{ name: 'ItemDetail', params: { uuid: favorite.itemUuid }}"
                    style="display: inline-block;text-decoration:none;"
                  >
                    <v-card-title
                      class="font-weight-bold"
                      v-text="favorite.name"
                    />
                  </router-link>
                  <v-card-text>
                    単価: {{ favorite.price.toLocaleString() }} pt<br>
                    登録日: {{ favorite.createdAt }}<br>
                    有効期限: {{ favorite.expirationPeriod ? `交換から ${favorite.expirationPeriod} 日間` : "なし" }}
                  </v-card-text>
                </v-col>
                <v-col
                  class="d-flex justify-center"
                >
                  <v-card-actions
                    class="justify-end flex-column pb-5"
                  >
                    <item-modal-button
                      button-text="カートに入れる"
                      button-color="primary"
                      button-icon="mdi-cart-arrow-down"
                      button-width="200"
                      :button-disabled="false"
                      :item="favorite"
                      @ok-action="addToCart"
                    />
                    <v-btn
                      rounded
                      color="error"
                      width="200"
                      class="outset mt-3"
                      outlined
                      @click="removeFromFavorite(favorite.itemUuid)"
                    >
                      <v-icon
                        class="mr-2"
                      >
                        mdi-heart-remove
                      </v-icon>
                      お気に入りから削除
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              v-else
              class="mb-10 welfare-disabled-card outset"
            >
              <v-row
                dense
                class="mx-3 my-5"
              >
                <v-col cols="3" />
                <v-col
                  cols="6"
                  :class="['ml-10', 'pa-12']"
                >
                  <v-card-title
                    class="font-weight-bold"
                    v-text="favorite.name"
                  />
                  <v-card-subtitle class="font-weight-bold text-h7">
                    単価: {{ favorite.price.toLocaleString() }} pt
                  </v-card-subtitle>
                  <v-card-subtitle class="mb-8 font-weight-bold text-h7">
                    登録日:  {{ favorite.createdAt }}
                  </v-card-subtitle>
                  <v-card-text>
                    このアイテムは削除されているためカートへ追加できません
                  </v-card-text>
                </v-col>
                <v-col
                  class="d-flex justify-center"
                >
                  <v-card-actions
                    class="justify-end flex-column pb-5"
                  >
                    <v-btn
                      rounded
                      color="error"
                      width="200"
                      @click="removeFromFavorite(favorite.itemUuid)"
                    >
                      <v-icon class="mr-2">
                        mdi-heart-remove
                      </v-icon>
                      お気に入りから削除
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col
            v-if="!favoriteItems.length"
            class="text-center"
          >
            <v-icon
              x-large
              color="primary"
            >
              mdi-heart-off
            </v-icon>
            お気に入りにアイテムがありません
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ItemModalButton from '@/components/common/ItemModalButton'

export default {
  components: {
    ItemModalButton
  },

  props: {
    favoriteItems: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    addToCart (item, count, userNote) {
      this.$emit('addToCart', item.itemUuid, count, userNote)
    },
    removeFromFavorite (itemUuid) {
      this.$emit('removeFromFavorite', this, itemUuid)
    }
  }
}
</script>
