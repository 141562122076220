<template>
  <container />
</template>

<script>
import CommonMixin from '@/mixins/CommonMixin'
import ViewMixin from '@/mixins/ViewMixin'
import Container from '@/components/pages/user/favoriteItem/Container.js'

export default {
  components: {
    Container
  },
  mixins: [
    CommonMixin, ViewMixin
  ]
}
</script>
